import React from 'react';
import { ConsignmentType } from '../../../shared/backend';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import LibraryBooksIcon from '@mui/icons-material/Send';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class TransitManifestConsignmentAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private transitManifestTourIds: number[] | null,
    private openDialog: (tourIds: number[], consignmentType: ConsignmentType) => void,
  ) {
    super(
      'transit-manifest-consignment',
      t('Transit manifest', { ns: 'customs' }),
      t('Transit manifest consignment', { ns: 'customs' }),
      <LibraryBooksIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.transitManifestTourIds;
  }

  public isDisabled(): boolean {
    return !this.transitManifestTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.transitManifestTourIds?.length) {
      this.openDialog(this.transitManifestTourIds, ConsignmentType.TRANSIT_MANIFEST);
    }
    return Promise.resolve();
  }
}
