import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import GavelIcon from '@mui/icons-material/Gavel';
import { INotifications } from '../../../hooks/useNotifications';
import { CustomsService } from '../../../services/customs-service/customs.service';
import { JobError, JobService } from '../../../services/job-service/job.service';
import { t } from 'i18next';
import { DownloadUtil } from '../../../shared/helper/download-util';

export class CollectiveReferenceAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private tourIdsForCollectiveReference: number[] | null,
  ) {
    super(
      'collective-reference',
      t('Collective reference', { ns: 'customs' }),
      null,
      <GavelIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.tourIdsForCollectiveReference;
  }

  public isDisabled(): boolean {
    return !this.tourIdsForCollectiveReference?.length;
  }

  public async execute(): Promise<void> {
    if (this.tourIdsForCollectiveReference?.length) {
      await this.handleCreateCollectiveReference(this.tourIdsForCollectiveReference);
    }
    return Promise.resolve();
  }

  private async handleCreateCollectiveReference(tourIds: number[]): Promise<void> {
    const jobData = await CustomsService.createCollectiveReferences(tourIds);

    try {
      const jobObject = await JobService.getInstance().addJobPromise(jobData.jobId.toString());
      const pdfBuffer = jobObject.returnvalue.file;

      if (pdfBuffer) {
        this.notifications.addSuccess(
          t('Collective references for tours {{tourIds}} have been successfully created and are being downloaded', {
            tourIds: tourIds.join(', '),
            ns: 'customs',
          }),
        );
        DownloadUtil.downloadPdfFile(pdfBuffer, JobService.getJobResultFileName(jobObject));
      } else {
        this.notifications.addError(
          t('Document from job {{jobId}} could not be downloaded', {
            jobId: jobData.jobId,
            ns: 'customs',
          }),
        );
      }

      this.mutate();
    } catch (error) {
      if (error instanceof JobError) {
        this.notifications.addError(
          t('Error when creating the collective references for the tours {{tourIds}}: {{message}}', {
            tourIds: tourIds.join(', '),
            message: error.getJobState().failedReason,
            ns: 'customs',
          }),
        );
      }
    }
  }
}
