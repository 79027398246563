import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import KeyboardReturnIcon from '@mui/icons-material/Send';
import { INotifications } from '../../../hooks/useNotifications';
import { TourService } from '../../../services/tour-service/tour.service';
import { t } from 'i18next';
import { DownloadUtil } from '../../../shared/helper/download-util';

export class DeImportCsvAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private batch: {
      tourBatchId: string | null;
    },
    private isSingleTour: boolean | null,
    private deImportTourIds: number[] | null,
  ) {
    super('de-import-csv', t('DE import CSV', { ns: 'customs' }), null, <KeyboardReturnIcon />, mutate, notifications);
  }

  public isAvailable(): boolean {
    return !!this.batch.tourBatchId && !this.isSingleTour && !!this.deImportTourIds;
  }

  public isDisabled(): boolean {
    return !this.deImportTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.deImportTourIds?.length && this.batch.tourBatchId) {
      await this.handleCreateDeImportCsv(this.batch.tourBatchId);
    }
    return Promise.resolve();
  }

  private async handleCreateDeImportCsv(tourBatchId: string): Promise<void> {
    try {
      const response = await TourService.getDeImportCsv(tourBatchId);

      const filename = `${tourBatchId}.csv`;

      DownloadUtil.downloadFile(new Blob([response]), filename, 'text/csv');
    } catch (error) {
      this.notifications.addError(error);
    }
  }
}
