import React, { useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Buffer } from 'buffer';
import { JobDto, JobService, ReducedJobDto } from '../../../services/job-service/job.service';
import { useNotifications } from '../../../hooks/useNotifications';
import { JobQueueJobs } from '../../../shared/backend';
import { useTranslation } from 'react-i18next';
import { DownloadUtil } from '../../../shared/helper/download-util';

export interface JobDownloadCellProps {
  job: ReducedJobDto;
}

const JobDownloadCell = (props: JobDownloadCellProps) => {
  const { t } = useTranslation('jobs');
  const notificationHandler = useNotifications();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleFileDownload = async (job: ReducedJobDto): Promise<void> => {
    setIsDownloading(true);

    let jobData: JobDto | null = null;

    try {
      // Returnvalue is not available in job list and has to be fetched separately
      jobData = await JobService.getInstance().getJob(job.id);
    } catch (error) {
      notificationHandler.addError(error);
      return;
    } finally {
      setIsDownloading(false);
    }

    const filebuffer = jobData?.returnvalue?.file;

    if (filebuffer) {
      const filename = JobService.getJobResultFileName(job);

      if (jobData?.name === JobQueueJobs.EMK_DOCUMENTS_CREATION) {
        const zipBuffer = Buffer.from(filebuffer, 'binary');
        DownloadUtil.downloadFile(zipBuffer, filename, `application/zip`);
      } else {
        DownloadUtil.downloadPdfFile(filebuffer, filename);
      }
    } else {
      notificationHandler.addError(t(`Document from job {{jobId}} could not be downloaded`, { jobId: jobData?.id }));
    }
  };

  return (
    <>
      <IconButton onClick={() => handleFileDownload(props.job)}>
        <DownloadIcon fontSize="small" />
      </IconButton>
      {isDownloading && <CircularProgress size="1em" />}
    </>
  );
};

export default JobDownloadCell;
