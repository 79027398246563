import React from 'react';
import { ConsignmentType } from '../../../shared/backend';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class EnsConsignmentsAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private ensTourIds: number[] | null,
    private openDialog: (tourIds: number[], consignmentType: ConsignmentType) => void,
  ) {
    super(
      'ens-consignment',
      t('ENS', { ns: 'customs' }),
      t('ENS consignments', { ns: 'customs' }),
      <LibraryBooksIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.ensTourIds;
  }

  public isDisabled(): boolean {
    return !this.ensTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.ensTourIds?.length) {
      this.openDialog(this.ensTourIds, ConsignmentType.ENS);
    }
    return Promise.resolve();
  }
}
