import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import GavelIcon from '@mui/icons-material/Gavel';
import { TourType } from '../../../shared/backend';
import { CustomsService } from '../../../services/customs-service/customs.service';
import { INotifications } from '../../../hooks/useNotifications';
import { JobError, JobService } from '../../../services/job-service/job.service';
import { t } from 'i18next';
import { DownloadUtil } from '../../../shared/helper/download-util';

export class BatchInvoiceAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    public batch: {
      tourBatchId: string | null;
    },
    private type: TourType,
    private isSingleTour: boolean | null,
    private setBatchInvoiceProgress: (progress: number) => void,
    batchInvoiceProgress: number,
  ) {
    const progressText = batchInvoiceProgress > 0 ? ` (${batchInvoiceProgress}%)` : '';
    super(
      'batch-invoice',
      `${t('Batch Invoice', { ns: 'customs' })}${progressText}`,
      null,
      <GavelIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.batch.tourBatchId && this.type === TourType.TOUR && !this.isSingleTour;
  }

  public isDisabled(): boolean {
    return !this.batch.tourBatchId;
  }

  public async execute(): Promise<void> {
    if (this.batch.tourBatchId) {
      await this.handleCreateTourBatchInvoices(this.batch.tourBatchId);
    }
    return Promise.resolve();
  }

  private async handleCreateTourBatchInvoices(tourBatchId: string): Promise<void> {
    const jobData = await CustomsService.createTourBatchInvoices({ tourBatchId });

    try {
      const jobObject = await JobService.getInstance().addJobPromise(jobData.jobId.toString(), (_, { progress }) =>
        this.setBatchInvoiceProgress(progress * 100),
      );

      const binaryBuffer = jobObject.returnvalue.file;

      if (binaryBuffer) {
        this.notifications.addSuccess(
          t('Invoices for tour batch {{tourBatchId}} have been successfully created and are being downloaded', {
            tourBatchId,
            ns: 'customs',
          }),
        );
        const zipBuffer = Buffer.from(binaryBuffer, 'binary');
        DownloadUtil.downloadFile(zipBuffer, JobService.getJobResultFileName(jobObject), `application/zip`);
      } else {
        this.notifications.addError(
          t('Document from job {{jobId}} could not be downloaded', { jobId: jobData.jobId, ns: 'customs' }),
        );
      }

      this.mutate();
    } catch (error) {
      if (error instanceof JobError) {
        this.notifications.addError(
          t('Error while creating invoices for tour batch {{tourBatchId}}: {{message}}', {
            tourBatchId,
            message: error.getJobState().failedReason,
            ns: 'customs',
          }),
        );
      } else {
        this.notifications.addError(error);
      }
    } finally {
      this.setBatchInvoiceProgress(0);
    }
  }
}
