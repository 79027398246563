import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import GavelIcon from '@mui/icons-material/Gavel';
import { CustomsService } from '../../../services/customs-service/customs.service';
import { JobError, JobService } from '../../../services/job-service/job.service';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';
import { DownloadUtil } from '../../../shared/helper/download-util';

export class TransitDepartureDocumentAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private tourIdsForTransitDepartureDocument: number[] | null,
  ) {
    super(
      'transit-departure-document',
      t('Transit departure document', { ns: 'customs' }),
      null,
      <GavelIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.tourIdsForTransitDepartureDocument;
  }

  public isDisabled(): boolean {
    return !this.tourIdsForTransitDepartureDocument?.length;
  }

  public async execute(): Promise<void> {
    if (this.tourIdsForTransitDepartureDocument?.length) {
      await this.handleCreateTransitDepartureDocument(this.tourIdsForTransitDepartureDocument);
    }
    return Promise.resolve();
  }

  private async handleCreateTransitDepartureDocument(tourIds: number[]): Promise<void> {
    const jobData = await CustomsService.createTransitDepartureDocument(tourIds);

    try {
      const jobObject = await JobService.getInstance().addJobPromise(jobData.jobId.toString());
      const pdfBuffer = jobObject.returnvalue.file;

      if (pdfBuffer) {
        this.notifications.addSuccess(
          t('Transit departure document for tours {{tourIds}} was successfully created and is being downloaded.', {
            tourIds: tourIds.join(', '),
            ns: 'customs',
          }),
        );
        DownloadUtil.downloadPdfFile(pdfBuffer, JobService.getJobResultFileName(jobObject));
      } else {
        this.notifications.addError(
          t('Document from job {{jobId}} could not be downloaded', { jobId: jobData.jobId, ns: 'customs' }),
        );
      }

      this.mutate();
    } catch (error) {
      if (error instanceof JobError) {
        this.notifications.addError(
          t('Error when creating the transit output document for the tours {{tourIds}}: {{message}}', {
            tourIds: tourIds.join(', '),
            message: error.getJobState().failedReason,
            ns: 'customs',
          }),
        );
      }
    }
  }
}
