import fileDownload from 'js-file-download';

export class DownloadUtil {
  public static downloadFile(
    data: string | ArrayBuffer | ArrayBufferView | Blob,
    filename: string,
    mime?: string,
    bom?: string,
  ): void {
    fileDownload(data, filename, mime, bom);
  }

  public static downloadPdfFile(base64Data: string, filename: string): void {
    const buffer = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
    fileDownload(buffer, filename, `application/pdf`);
  }
}
