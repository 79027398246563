import { INotifications } from '../../../hooks/useNotifications';

export abstract class AbstractCustomsAction {
  protected constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly tooltip: string | null,
    public icon: React.ReactNode | null,
    protected mutate: () => void,
    protected notifications: INotifications,
  ) {}

  public abstract isAvailable(): boolean;
  public abstract isDisabled(): boolean;
  public abstract execute(): Promise<void>;
}
