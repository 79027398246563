import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import CreateConsignmentDialog from './CreateConsignmentDialog';
import { IAdditionalConsignmentData, CustomsService } from '../../services/customs-service/customs.service';
import { useNotifications } from '../../hooks/useNotifications';
import { JobError, JobService } from '../../services/job-service/job.service';
import { TourService } from '../../services/tour-service/tour.service';
import { ConsignmentState, ConsignmentType, IsoCountryCode, TourType } from '../../shared/backend';
import ButtonAsync from '../../shared/components/ButtonAsync';
import DeImportDocumentsDialog from '../../shared/components/de-import-documents-dialog/DeImportDocumentsDialog';
import { BatchInvoiceAction } from './actions/BatchInvoiceAction';
import { CollectiveReferenceAction } from './actions/CollectiveReferenceAction';
import { DeBatchImportPositionsCsvAction } from './actions/DeBatchImportPositionsCsvAction';
import { DeImportCsvAction } from './actions/DeImportCsvAction';
import { DeImportDocumentsAction } from './actions/DeImportDocumentsAction';
import { EmkDocumentsAction } from './actions/EmkDocumentsAction';
import { EnsConsignmentsAction } from './actions/EnsConsignmentsAction';
import { ExportConsignmentsAction } from './actions/ExportConsignmentsAction';
import { ExportGvmsConsignmentsAction } from './actions/ExportGvmsConsignmentsAction';
import { ImportConsignmentAction } from './actions/ImportConsignmentsAction';
import { ImportGvmsConsignmentAction } from './actions/ImportGvmsConsignmentAction';
import { PostNordCsvAction } from './actions/PostNordCsvAction';
import { SumAConsignmentsAction } from './actions/SumAConsignmentAction';
import { TransitArrivalConsignmentsAction } from './actions/TransitArrivalConsignmentsAction';
import { TransitConsignmentAction } from './actions/TransitConsignmentAction';
import { TransitDepartureConsignmentsAction } from './actions/TransitDepartureConsignmentsAction';
import { TransitDepartureDocumentAction } from './actions/TransitDepartureDocumentAction';
import { TransitManifestConsignmentAction } from './actions/TransitManifestConsignmentAction';
import { useTranslation } from 'react-i18next';

type Props = {
  batch: {
    tourBatchId: string | null;
    dispatchCountry: IsoCountryCode;
    destinationCountry: IsoCountryCode;
    notes: string[];
  };
  isSingleTour?: boolean; // Whether this contains actions for a single tour or a whole batch
  isLoading: boolean;
  type: TourType;
  deImportTourIds?: number[];
  importTourIds?: number[];
  exportTourIds?: number[];
  exportGvmsTourIds?: number[];
  importGvmsTourIds?: number[];
  transitTourIds?: number[];
  transitManifestTourIds?: number[];
  transitDepartureTourIds?: number[];
  transitArrivalTourIds?: number[];
  tourIdsWithEmk?: number[];
  tourIdsForCollectiveReference?: number[];
  tourIdsForTransitDepartureDocument?: number[];
  sumATourIds?: number[];
  ensTourIds?: number[];
};

const CustomsActions: React.FC<Props> = ({
  batch,
  isSingleTour = null,
  isLoading,
  type,
  deImportTourIds = null,
  importTourIds = null,
  exportTourIds = null,
  exportGvmsTourIds = null,
  importGvmsTourIds = null,
  transitTourIds = null,
  transitManifestTourIds = null,
  transitDepartureTourIds = null,
  transitArrivalTourIds = null,
  tourIdsWithEmk = null,
  tourIdsForCollectiveReference = null,
  tourIdsForTransitDepartureDocument = null,
  sumATourIds = null,
  ensTourIds = null,
}) => {
  const { t } = useTranslation('customs');
  const notifications = useNotifications();

  const { tourBatchId } = batch;
  const { data, mutate } = TourService.useToursByBatch(tourBatchId);

  const [isCreateConsignmentDialogOpen, setIsCreateConsignmentDialogOpen] = useState<{
    tourIds: number[];
    consignmentType: ConsignmentType;
  } | null>(null);
  const [isDeImportDialogOpen, setIsDeImportDialogOpen] = useState(false);
  const [postNordZipProgress, setPostNordZipProgress] = useState(0);
  const [batchInvoiceProgress, setBatchInvoiceProgress] = useState(0);

  const tourDispatchCountry = type === TourType.TOUR ? batch.dispatchCountry : batch.destinationCountry;

  const process = data[0]?.tour.process;

  const showDeImportModal = () => setIsDeImportDialogOpen(true);

  const showCreateConsignmentDialog = (tourIds: number[], consignmentType: ConsignmentType) =>
    setIsCreateConsignmentDialogOpen({ tourIds, consignmentType });

  const actions = [
    new ExportConsignmentsAction(mutate, notifications, batch, type, exportTourIds, showCreateConsignmentDialog),
    new ExportGvmsConsignmentsAction(mutate, notifications, exportGvmsTourIds, showCreateConsignmentDialog),
    new TransitManifestConsignmentAction(mutate, notifications, transitManifestTourIds, showCreateConsignmentDialog),
    new TransitConsignmentAction(mutate, notifications, transitTourIds, showCreateConsignmentDialog),
    new ImportConsignmentAction(mutate, notifications, batch, type, importTourIds, showCreateConsignmentDialog),
    new TransitDepartureConsignmentsAction(mutate, notifications, transitDepartureTourIds, showCreateConsignmentDialog),
    new EnsConsignmentsAction(mutate, notifications, ensTourIds, showCreateConsignmentDialog),
    new ImportGvmsConsignmentAction(mutate, notifications, importGvmsTourIds, showCreateConsignmentDialog),
    new TransitArrivalConsignmentsAction(mutate, notifications, transitArrivalTourIds, showCreateConsignmentDialog),
    new SumAConsignmentsAction(mutate, notifications, sumATourIds, showCreateConsignmentDialog),
  ];

  const documentActions = [
    new EmkDocumentsAction(mutate, notifications, batch, tourIdsWithEmk),
    new DeImportDocumentsAction(mutate, notifications, deImportTourIds, showDeImportModal),
    new DeImportCsvAction(mutate, notifications, batch, isSingleTour, deImportTourIds),
    new DeBatchImportPositionsCsvAction(mutate, notifications, batch, type, isSingleTour, deImportTourIds),
    new CollectiveReferenceAction(mutate, notifications, tourIdsForCollectiveReference),
    new TransitDepartureDocumentAction(mutate, notifications, tourIdsForTransitDepartureDocument),
    new PostNordCsvAction(
      mutate,
      notifications,
      batch,
      type,
      process,
      isSingleTour,
      setPostNordZipProgress,
      postNordZipProgress,
    ),
    new BatchInvoiceAction(
      mutate,
      notifications,
      batch,
      type,
      isSingleTour,
      setBatchInvoiceProgress,
      batchInvoiceProgress,
    ),
  ];

  const waitForConsignmentJob = async (jobId: string, tourIds: number[]) => {
    try {
      const jobObject = await JobService.getInstance().addJobPromise(jobId);

      if (!jobObject.returnvalue) {
        return;
      }

      const errorMessages: { tourId: number; message: string | undefined }[] = [];
      const successStates: { tourId: number; consignmentState: ConsignmentState }[] = [];

      for (const tourId of tourIds) {
        const tourResult = jobObject.returnvalue.result?.find((result) => result.tourIds?.find((id) => tourId === id));

        const consignmentState = tourResult?.state ?? ConsignmentState.NOT_CREATED;

        if (consignmentState === ConsignmentState.ERROR || consignmentState === ConsignmentState.CREATED_WITH_ERRORS) {
          errorMessages.push({ tourId, message: tourResult?.message });
        } else {
          successStates.push({ tourId, consignmentState });
        }
      }

      const successTours = successStates.filter(
        ({ consignmentState }) => consignmentState === ConsignmentState.SUCCESS,
      );
      const otherTours = successStates.filter(({ consignmentState }) => consignmentState !== ConsignmentState.SUCCESS);

      if (successTours.length) {
        notifications.addSuccess(
          t('Consignments for the tours {{tourIds}} were successfully created.', {
            tourIds: successTours.map(({ tourId }) => tourId).join(', '),
          }),
        );
      }

      if (otherTours.length) {
        notifications.addInfo(
          t(
            'Consignments for the tours {{tourIds}} were created with the following states:\n{{tourConsignmentStates}}',
            {
              tourIds: otherTours.map(({ tourId }) => tourId).join(', '),
              tourConsignmentStates: otherTours
                .map(({ tourId, consignmentState }) => `${tourId}: ${consignmentState}`)
                .join('\n'),
              count: otherTours.length,
            },
          ),
        );
      }

      if (errorMessages.length) {
        for (const { tourId, message } of errorMessages) {
          notifications.addError(
            `${t('Error when creating the consignments for the tour {{tourId}}', {
              tourId,
            })}${message ? `:\n${message}` : ''}`,
          );
        }
      }
    } catch (error) {
      if (error instanceof JobError) {
        notifications.addError(
          t(`Error when creating the consignments for the tours {{tourIds}}: {{message}}`, {
            tourIds: tourIds.join(', '),
            message: error.getJobState().failedReason,
            count: tourIds.length,
          }),
        );
      } else {
        notifications.addError(error);
      }
    } finally {
      mutate();
    }
  };

  const handleCreateConsignments = async (
    tourIds: number[],
    consignmentType: ConsignmentType,
    additionalConsignmentData: IAdditionalConsignmentData = {},
  ) => {
    try {
      const { jobId } = await CustomsService.createConsignments(tourIds, consignmentType, additionalConsignmentData);

      waitForConsignmentJob(jobId, tourIds);
    } catch (error) {
      notifications.addError(error);
    } finally {
      await mutate(TourService.toursByBatchMutatorFactory(consignmentType, tourIds));
    }
  };

  if (isLoading) {
    return (
      <>
        <Skeleton
          width={120}
          height={50}
        />
        <Skeleton
          width={180}
          height={50}
        />
        <a
          href="https://www.youtube.com/watch?v=0Wi8Fv0AJA4"
          target="_blank"
          rel="noreferrer"
        >
          <Skeleton
            width={160}
            height={50}
          />
        </a>
      </>
    );
  }

  return (
    <>
      <CreateConsignmentDialog
        tourDispatchCountry={tourDispatchCountry}
        onClose={() => setIsCreateConsignmentDialogOpen(null)}
        open={!!isCreateConsignmentDialogOpen}
        notes={batch.notes}
        tourIds={isCreateConsignmentDialogOpen?.tourIds}
        consignmentType={isCreateConsignmentDialogOpen?.consignmentType}
        createConsignments={async (additionalConsignmentData) =>
          isCreateConsignmentDialogOpen
            ? handleCreateConsignments(
                isCreateConsignmentDialogOpen.tourIds,
                isCreateConsignmentDialogOpen.consignmentType,
                additionalConsignmentData,
              )
            : Promise.reject('Dialog closed')
        }
      />

      {deImportTourIds && isDeImportDialogOpen && (
        <DeImportDocumentsDialog
          tourType={type}
          isSingleTour={isSingleTour ?? undefined}
          tourIds={deImportTourIds}
          onClose={() => setIsDeImportDialogOpen(false)}
        />
      )}

      {actions
        .filter((action) => action.isAvailable())
        .map((action) => (
          <ButtonAsync
            key={action.id}
            variant="contained"
            color="primary"
            size="small"
            startIcon={action.icon}
            disabled={action.isDisabled()}
            onClick={() => action.execute()}
            title={action.tooltip ?? undefined}
          >
            {action.name}
          </ButtonAsync>
        ))}

      {documentActions
        .filter((action) => action.isAvailable())
        .map((action) => (
          <ButtonAsync
            key={action.id}
            variant="contained"
            color="secondary"
            size="small"
            startIcon={action.icon}
            disabled={action.isDisabled()}
            onClick={() => action.execute()}
            title={action.tooltip ?? undefined}
          >
            {action.name}
          </ButtonAsync>
        ))}
    </>
  );
};

export default CustomsActions;
