import { useSnackbar, VariantType } from 'notistack';
import { useCallback, useMemo } from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

export interface INotifications {
  addError(error: unknown, duration?: number | null, key?: string): void;
  addWarning(message: string, duration?: number | null, key?: string): void;
  addInfo(message: string, duration?: number | null, key?: string): void;
  addSuccess(message: string, duration?: number | null, key?: string): void;
  close(key: string): void;
}

export const useNotifications = (): INotifications => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addError = useCallback(
    (error: unknown, duration: number | null = 5000, key?: string): void => {
      let message: string = '';
      let variant: VariantType = 'error';

      if (axios.isAxiosError(error) && error.code === 'ERR_NETWORK') {
        const errorMessage = error.message;
        message = t('Network error: Server not available. ({{errorMessage}})', { errorMessage });
      } else if (axios.isAxiosError(error) && error.response) {
        console.warn('Axios error response: ', {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers,
        });

        if (error.response.status === 404) {
          variant = 'warning';
        }

        // ArrayBuffer handling
        if (error.response.data instanceof ArrayBuffer) {
          const decoder = new TextDecoder('utf-8');
          try {
            error.response.data = JSON.parse(decoder.decode(error.response.data));
          } catch (e) {
            console.log('Could not convert ArrayBuffer to error object', error);
          }
        }

        const responseData = error.response?.data as Record<string, unknown>;

        if (Array.isArray(responseData?.message)) {
          const errorArray = responseData.message;
          message = errorArray[0];
        } else if (responseData?.message) {
          message = responseData.message.toString();

          if (responseData.context) {
            message += ` | ${JSON.stringify(responseData.context)}`;
          }
        } else {
          const url = error.response?.config?.url || '<unknown url>';
          const status = error.response.status;
          message = t('The server responds with an error code {{status}} for {{url}}', { status, url });
        }
      } else if (axios.isAxiosError(error) && error.request) {
        console.log(error.request);
        message = t('The server does not respond');
      } else {
        console.log(error);
        message = t('An error has occurred: {{error}}', { error });
      }

      console.error('[ERROR] ', message);

      enqueueSnackbar(message || t('An error without description has occurred'), {
        variant,
        autoHideDuration: duration,
        key,
      });
    },
    [enqueueSnackbar, t],
  );

  const addWarning = useCallback(
    (message: string, duration: number | null = 5000, key?: string) => {
      console.warn('[WARNING] ', message);

      enqueueSnackbar(message, { variant: 'warning', autoHideDuration: duration, key });
    },
    [enqueueSnackbar],
  );

  const addInfo = useCallback(
    (message: string, duration: number | null = 5000, key?: string) => {
      console.info('[INFO] ', message);

      enqueueSnackbar(message, { variant: 'info', autoHideDuration: duration, key });
    },
    [enqueueSnackbar],
  );

  const addSuccess = useCallback(
    (message: string, duration: number | null = 5000, key?: string) => {
      console.info('[SUCCESS] ', message);

      enqueueSnackbar(message, { variant: 'success', autoHideDuration: duration, key });
    },
    [enqueueSnackbar],
  );

  const close = useCallback(
    (key: string) => {
      closeSnackbar(key);
    },
    [closeSnackbar],
  );

  const value = useMemo(
    () => ({ addError, addWarning, addInfo, addSuccess, close }),
    [addError, addWarning, addInfo, addSuccess, close],
  );

  return value;
};
