import {
  Toolbar,
  IconButton,
  Box,
  Typography,
  AppBar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { yellow } from '@mui/material/colors';
import React from 'react';
import { ExportoEnv } from '../../helper/globals';
import UserAvatar from '../UserAvatar';
import { WeightCounter } from '../WeightCounter/WeightCounter';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { Config } from '../../helper/env/helper';
import logo from './../../../assets/img/logo/exporto-logo.svg';
import logoStaging from './../../../assets/img/logo/staging-logo.min.svg';
import InfoToast from '../info-toast/InfoToast';
import { useUserInfo } from '../../../services/user-service/user.service';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../backend';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';

const isStaging = window.location.hostname === 'staging.app.exporto.de';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setFunTime: (enabled: boolean) => void;
  drawerWidth: number;
};

const ExportoAppBar: React.FC<Props> = ({ open, setOpen, setFunTime, drawerWidth }) => {
  const auth = useAuthentication();
  const userInfo = useUserInfo();
  const { t } = useTranslation();

  const [infoMsg, setInfoMsg] = React.useState('');
  const [anchorUserMenu, setAnchorUserMenu] = React.useState<null | HTMLElement>(null);

  const roleLabels: Record<UserRole, string> = {
    [UserRole.ADMIN]: t('Admin'),
    [UserRole.PICKER]: t('Picker'),
    [UserRole.WAREHOUSE_AGENT]: t('Warehouse agent'),
    [UserRole.WAREHOUSE_SUPERVISOR]: t('Warehouse supervisor'),
    [UserRole.CUSTOMS_AGENT]: t('Customs agent'),
    [UserRole.CUSTOMER_CARE_AGENT]: t('Customer care agent'),
    [UserRole.CUSTOMER]: t('Customer'),
  };

  const handleTestSystemBannerClick = () => {
    // Wenn der Nutzer klickt soll ein info Toast kommen das es sich um ein Test System handelt
    setInfoMsg(
      'Es handelt sich um eine Demo-Version. Hier können keine Bestellungen verarbeitet werden. Unter: https://app.exporto.de erreichen Sie die Produktionsversion.',
    );
  };

  return (
    <AppBar
      position="fixed"
      sx={(theme) => ({
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open
          ? {
              width: `calc(100% - ${drawerWidth}px)`,
              marginLeft: drawerWidth,
              transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }
          : undefined),
      })}
    >
      <Toolbar
        sx={(theme) => ({
          backgroundColor:
            Config.ENV !== ExportoEnv.production
              ? theme.palette.mode === 'light'
                ? yellow[200]
                : '#4c4930'
              : undefined,
          color: Config.ENV !== ExportoEnv.production ? 'rgba(0, 0, 0, 0.87)' : undefined,
        })}
      >
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2, display: open ? 'none' : undefined }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Box>
          <Box
            component="img"
            sx={{ height: '30px', verticalAlign: 'text-top' }}
            src={isStaging ? logoStaging : logo}
            alt="exporto logo"
            onDoubleClick={auth.isStaff() ? () => setFunTime(true) : undefined}
          />

          {Config.ENV !== ExportoEnv.production && (
            <Typography
              onClick={handleTestSystemBannerClick}
              style={{ color: 'red', float: 'right', marginLeft: '1rem' }}
              variant="h5"
            >
              {Config.ENV === ExportoEnv.staging ? 'DEMOSYSTEM' : 'DEV System'}
            </Typography>
          )}
        </Box>

        <Box flexGrow={1}></Box>

        {auth.isStaff() && (
          <Box>
            <WeightCounter />
          </Box>
        )}

        <Box sx={{ flexGrow: 0 }}>
          {userInfo.data && (
            <>
              <IconButton
                onClick={(ev) => setAnchorUserMenu(ev.currentTarget)}
                sx={{ p: 0 }}
              >
                <UserAvatar
                  user={userInfo.data}
                  sx={{
                    border: '3px solid #fff',
                  }}
                />
              </IconButton>

              <Menu
                sx={{ mt: 1 }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorEl={anchorUserMenu}
                open={!!anchorUserMenu}
                onClose={() => setAnchorUserMenu(null)}
                onClick={() => setAnchorUserMenu(null)}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  },
                }}
              >
                <ListItem dense>
                  <ListItemText
                    sx={{ m: 0 }}
                    primary={`${userInfo.data.firstName} ${userInfo.data.lastName}`}
                    secondary={
                      <span>
                        {userInfo.data.email}
                        <br />
                        {roleLabels[userInfo.data.role]}
                      </span>
                    }
                  />
                </ListItem>

                <Divider />

                <MenuItem
                  component="a"
                  target="_blank"
                  rel="noopener"
                  href="https://support.exporto.de/"
                >
                  <ListItemIcon>
                    <HelpIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('FAQ')}</ListItemText>
                </MenuItem>

                <MenuItem
                  component={Link}
                  to={`/settings`}
                >
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('Settings')}</ListItemText>
                </MenuItem>

                <MenuItem
                  component={Link}
                  to={`/logout`}
                >
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('Logout')}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Toolbar>

      <InfoToast
        infoMsg={infoMsg}
        open={infoMsg !== ''}
        onClose={() => setInfoMsg('')}
      ></InfoToast>
    </AppBar>
  );
};

export default ExportoAppBar;
