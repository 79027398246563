import React, { useState } from 'react';
import { ProcessWithCustomerDto } from '../../../../services/process-service/process.service';
import { Card, CardHeader, Typography, CardContent, Stack, FormControlLabel, Switch } from '@mui/material';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AddShipmentMethodForm from '../../customers/components/customer-details/components/AddShipmentMethodForm';
import DefaultShipmentMethodView from '../../customers/components/customer-details/components/default-shipment-method-view/defaultShipmentMethodView';
import ShipmentMethodCard from './shipment-method-card/shipmentMethodCard';
import * as ShipmentMethodService from '../../../../services/shipment-method-service/shipmentMethod.service';
import * as CarrierService from '../../../../services/carrier-service/carrier.service';
import { useNotifications } from '../../../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';

type Props = {
  process: ProcessWithCustomerDto;
};

const ProcessShippingMethods: React.FC<Props> = ({ process }) => {
  const { t } = useTranslation('CRM');

  const notifications = useNotifications();
  const carriers = CarrierService.useCarriers();
  const [showDeleted, setShowDeleted] = useState(false);
  const shipmentMethods = ShipmentMethodService.useShipmentMethodsByProcess(process.processId, showDeleted);

  const postShipmentMethod = async (
    body: ShipmentMethodService.CreateShipmentMethodDto,
    processId: number,
  ): Promise<ShipmentMethodService.ShipmentMethodDto | undefined> => {
    try {
      return await ShipmentMethodService.createShipmentMethod(body, processId);
    } catch (error) {
      notifications.addError(error);
    }
  };

  const putShipmentMethod = async (
    body: Partial<ShipmentMethodService.UpdateShipmentMethodDto>,
    processId: number,
    shipmentMethodId: number,
  ): Promise<ShipmentMethodService.ShipmentMethodDto | undefined> => {
    try {
      return await ShipmentMethodService.updateShipmentMethod(body, processId, shipmentMethodId);
    } catch (error) {
      notifications.addError(error);
    }
  };

  const deleteShipmentMethod = async (processId: number, shipmentMethodId: number): Promise<true | undefined> => {
    try {
      await ShipmentMethodService.deleteShipmentMethod(processId, shipmentMethodId);
      return true;
    } catch (error) {
      notifications.addError(error);
    }
  };

  const handleShipmentMethodCreate = async (
    body: ShipmentMethodService.CreateShipmentMethodDto,
    processId: number,
  ): Promise<ShipmentMethodService.ShipmentMethodDto | undefined> => {
    const newShipmentMethod = await postShipmentMethod(body, processId);
    if (newShipmentMethod) {
      shipmentMethods.mutate();

      return newShipmentMethod;
    }
  };

  const handleShipmentMethodUpdate = async (
    body: Partial<ShipmentMethodService.UpdateShipmentMethodDto>,
    shipmentMethodId: number,
    processId: number,
  ): Promise<ShipmentMethodService.ShipmentMethodDto | undefined> => {
    const updatedShipmentMethod = await putShipmentMethod(body, processId, shipmentMethodId);
    if (updatedShipmentMethod) {
      shipmentMethods.mutate();

      return updatedShipmentMethod;
    }
  };

  const handleShipmentMethodDelete = async (shipmentMethodId: number, processId: number): Promise<true | undefined> => {
    if (processId) {
      const deletedShipmentMethod = await deleteShipmentMethod(processId, shipmentMethodId);
      if (deletedShipmentMethod) {
        shipmentMethods.mutate();

        return deletedShipmentMethod;
      }
    }
    return;
  };

  const handleShipmentMethodRestore = async (
    shipmentMethodId: number,
    processId: number,
  ): Promise<ShipmentMethodService.ShipmentMethodDto | undefined> => {
    const restoredShipmentMethod = await ShipmentMethodService.restoreShipmentMethod(processId, shipmentMethodId);
    if (restoredShipmentMethod) {
      shipmentMethods.mutate();

      return restoredShipmentMethod;
    }
  };

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        title={<Typography variant="h5">{t('Shipping methods')}</Typography>}
        avatar={<DeliveryDiningIcon sx={{ fontSize: '2.5rem' }} />}
      />

      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
        >
          <FormControlLabel
            control={
              <Switch
                checked={showDeleted}
                onChange={(event) => setShowDeleted(event.target.checked)}
              />
            }
            label={t('Show deleted')}
          />

          <DefaultShipmentMethodView
            shipmentMethods={shipmentMethods.data}
            onShipmentMethodUpdate={handleShipmentMethodUpdate}
            processId={process.processId}
            returnShipment={true}
            disabled={showDeleted}
          />

          <DefaultShipmentMethodView
            shipmentMethods={shipmentMethods.data}
            onShipmentMethodUpdate={handleShipmentMethodUpdate}
            processId={process.processId}
            returnShipment={false}
            disabled={showDeleted}
          />
        </Stack>

        <Stack
          direction="column"
          spacing={5}
          my={5}
        >
          {shipmentMethods.data.length === 0 && (
            <Typography
              color="text.disabled"
              align="center"
              my={3}
            >
              {t('No shipping methods available.')}
            </Typography>
          )}
          {shipmentMethods.data.map((shipmentMethod, index) => (
            <ShipmentMethodCard
              key={shipmentMethod.shipmentMethodId}
              shipmentMethod={shipmentMethod}
              onShipmentMethodDelete={handleShipmentMethodDelete}
              onShipmentMethodUpdate={handleShipmentMethodUpdate}
              onShipmentMethodRestore={handleShipmentMethodRestore}
              availableCarriers={carriers.data}
              processId={process.processId}
            />
          ))}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
        >
          <AddShipmentMethodForm
            availableCarriers={carriers.data}
            onShipmentMethodCreate={handleShipmentMethodCreate}
            processId={process.processId}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProcessShippingMethods;
