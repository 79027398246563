import React, { useState } from 'react';
import { useNotifications } from '../../../hooks/useNotifications';
import { CreateDeImportDocumentsRequestDto, CustomsService } from '../../../services/customs-service/customs.service';
import { JobError, JobService } from '../../../services/job-service/job.service';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material';
import { DeclarationType, TourType } from '../../backend';
import ButtonAsync from '../ButtonAsync';
import { DownloadUtil } from '../../helper/download-util';

type Props = {
  tourIds: number[];
  tourType: TourType;
  isSingleTour?: boolean;
  onClose?: () => void;
};

const DeImportDocumentsDialog: React.FC<Props> = ({ tourIds, tourType, isSingleTour, onClose }) => {
  const notifications = useNotifications();
  const isReturnTour = tourType === TourType.RETOUR;

  const tourCustomsState = CustomsService.useTourCustomsState(isSingleTour && tourIds.length === 1 ? tourIds[0] : null);
  const importCustomsState = tourCustomsState.data?.IMPORT;
  const importConsignmentStates =
    importCustomsState?.declarationType === DeclarationType.BY_BATCH_IMPORT
      ? importCustomsState.consignments
      : [importCustomsState];

  const customsRegistrationNumbers = importConsignmentStates
    .map((consignmentState) => consignmentState?.customsRegistrationNumber)
    .filter((mrn) => !!mrn);

  const [aNumber, setANumber] = useState<string>(
    customsRegistrationNumbers.length ? customsRegistrationNumbers.join(', ') : '',
  );
  const [createCustomsGoodsList, setCreateCustomsGoodsList] = useState(true);
  const [create9DCAForm, setCreate9DCAForm] = useState(isReturnTour);
  const [combineShopsIn9DCAForm, setCombineShopsIn9DCAForm] = useState(true);

  const handleDownload = async () => {
    const options: CreateDeImportDocumentsRequestDto = {
      tourIds,
      aNumber: isSingleTour ? aNumber : undefined,
      createCustomsGoodsList: isSingleTour ? false : createCustomsGoodsList,
      create9DCAForm: isReturnTour && (isSingleTour ? true : create9DCAForm),
      combineShopsIn9DCAForm: isReturnTour && tourIds.length > 1 && create9DCAForm ? combineShopsIn9DCAForm : false,
    };
    const { jobId } = await CustomsService.createGermanImportDocuments(options);

    try {
      const jobObject = await JobService.getInstance().addJobPromise(jobId.toString());

      const pdfBuffer = jobObject.returnvalue.file;

      if (pdfBuffer) {
        notifications.addSuccess(`De Import Dokumente erfolgreich erstellt (Touren: ${tourIds.join(', ')})`);
        DownloadUtil.downloadPdfFile(pdfBuffer, JobService.getJobResultFileName(jobObject));
      } else {
        notifications.addError(`Dokument aus Job ${jobId} konnte nicht heruntergeladen werden`);
      }

      handleClose();
    } catch (error) {
      if (error instanceof JobError) {
        notifications.addError(`Fehler beim Erstellen der De Import Dokumente: ${error.getJobState().failedReason}`);
      } else {
        notifications.addError(`Datei konnte nicht erstellt werden. Unbekannter Fehler. ${error}`);
      }
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>De Import Dokumente</DialogTitle>
      <DialogContent>
        <DialogContentText mt={2}>Touren: {tourIds.join(', ')}</DialogContentText>
        <Stack
          direction={'column'}
          sx={{ mt: 4 }}
        >
          {(!isSingleTour || tourIds.length > 1) && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createCustomsGoodsList}
                    onChange={(event) => setCreateCustomsGoodsList(event.target.checked)}
                  />
                }
                label="Warenausweis + gesperrte Deklarationen"
              />
              {isReturnTour && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={create9DCAForm}
                        onChange={(event) => setCreate9DCAForm(event.target.checked)}
                      />
                    }
                    label="9DCA Formular"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={combineShopsIn9DCAForm}
                        onChange={(event) => setCombineShopsIn9DCAForm(event.target.checked)}
                        disabled={!create9DCAForm || tourIds.length === 1}
                      />
                    }
                    label="selbe Shops auf ein 9DCA zusammenfassen"
                  />
                </>
              )}
            </>
          )}

          {isSingleTour && tourIds.length === 1 && (
            <TextField
              variant="standard"
              value={aNumber}
              label="A-Nummer"
              onChange={(event) => setANumber(event.target.value)}
              autoFocus={true}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonAsync
          onClick={handleDownload}
          variant="contained"
          color="secondary"
          startIcon={<DownloadIcon fontSize="small" />}
        >
          Bestätigen &amp; Download
        </ButtonAsync>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeImportDocumentsDialog;
