import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import SendIcon from '@mui/icons-material/Send';
import { ConsignmentType, IsoCountryCode, TourType } from '../../../shared/backend';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class ExportConsignmentsAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    public batch: {
      destinationCountry: IsoCountryCode;
    },
    private type: TourType,
    private exportTourIds: number[] | null,
    private openDialog: (tourIds: number[], consignmentType: ConsignmentType) => void,
  ) {
    super(
      'export-consignments',
      t('Export', { ns: 'customs' }),
      t('Export consignments', { ns: 'customs' }),
      <SendIcon />,
      mutate,
      notifications,
    );
  }

  private get isNorwayProcessType(): boolean {
    return this.batch.destinationCountry === IsoCountryCode.NO;
  }

  private get isReturnFromNorway(): boolean {
    return this.type === TourType.RETOUR && this.isNorwayProcessType;
  }

  public isAvailable(): boolean {
    return !!this.exportTourIds && !this.isReturnFromNorway;
  }

  public isDisabled(): boolean {
    return !this.exportTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.exportTourIds?.length) {
      this.openDialog(this.exportTourIds, ConsignmentType.EXPORT);
    }
    return Promise.resolve();
  }
}
