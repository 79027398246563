import React from 'react';
import { ConsignmentType, IsoCountryCode, TourType } from '../../../shared/backend';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import SendIcon from '@mui/icons-material/Send';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class ImportConsignmentAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    public batch: {
      destinationCountry: IsoCountryCode;
    },
    private type: TourType,
    private importTourIds: number[] | null,
    private openDialog: (tourIds: number[], consignmentType: ConsignmentType) => void,
  ) {
    super(
      'import-consignments',
      t('Import', { ns: 'customs' }),
      t('Import consignments', { ns: 'customs' }),
      <SendIcon />,
      mutate,
      notifications,
    );
  }

  private get isNorwayProcessType(): boolean {
    return this.batch.destinationCountry === IsoCountryCode.NO;
  }

  private get isReturnFromNorway(): boolean {
    return this.type === TourType.RETOUR && this.isNorwayProcessType;
  }

  public isAvailable(): boolean {
    return !!this.importTourIds;
  }

  public isDisabled(): boolean {
    return !this.importTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.importTourIds?.length) {
      this.openDialog(
        this.importTourIds,
        this.batch.destinationCountry === IsoCountryCode.GB && this.type === TourType.RETOUR
          ? ConsignmentType.IMPORT_DE_RETURN_BATCH
          : this.isNorwayProcessType
            ? !this.isReturnFromNorway
              ? ConsignmentType.POST_NORD_IMPORT
              : ConsignmentType.IMPORT_DE_RETURN_BATCH
            : ConsignmentType.IMPORT,
      );
    }
    return Promise.resolve();
  }
}
