import { backendUri } from '../../shared/helper/env/helper';
import { IUseArrayResponse, IUseObjectResponse, SchemaType, api } from '../../shared/client';
import { swrFetcherWith404 } from '../../contexts/SWRProvider';
import useSWR from 'swr';
import { ProcessTypeOutboundTag } from '../../shared/backend';
import { ProcessTypeDto } from '../process-type-service/process-type.service';

export interface ProcessDto extends SchemaType<'ProcessDto'> {}
export interface ProcessWithCustomerDto extends SchemaType<'ProcessWithCustomerDto'> {}
export interface UpdateProcessDto extends SchemaType<'UpdateProcessDto'> {}
export interface FiscalRepresentationDto extends SchemaType<'FiscalRepresentationDto'> {}
export interface FiscalRepresentationUpdateDto extends SchemaType<'FiscalRepresentationUpdateDto'> {}
export interface ProcessShopSystemApiDto extends SchemaType<'ProcessShopSystemApiDto'> {}
export interface ProcessShopSystemApiUpdateDto extends SchemaType<'ProcessShopSystemApiUpdateDto'> {}
export interface CreatedJobDataDto extends SchemaType<'CreatedJobDataDto'> {}
export interface GetProcessStatsDto extends SchemaType<'GetProcessStatsDto'> {}

/**
 * This mapping is defined by the german customs for form field #34b.
 */
export const ORIGIN_AREAS = {
  '01': 'Schleswig-Holstein',
  '02': 'Hamburg',
  '03': 'Niedersachsen',
  '04': 'Bremen',
  '05': 'Nordrhein-Westfalen',
  '06': 'Hessen',
  '07': 'Rheinland-Pfalz',
  '08': 'Baden-Württemberg',
  '09': 'Bayern',
  '10': 'Saarland',
  '11': 'Berlin',
  '12': 'Brandenburg',
  '13': 'Mecklenburg-Vorpommern',
  '14': 'Sachsen',
  '15': 'Sachsen-Anhalt',
  '16': 'Thüringen',
  '99': 'Ausland',
};

export type OriginAreaId = keyof typeof ORIGIN_AREAS;

export namespace ProcessService {
  export function isProcessUsingVOEC(process: { processType: Pick<ProcessTypeDto, 'outboundTag'> }): boolean {
    if (!process.processType) {
      console.warn('Process type missing for VOEC check', process);
    }

    return process.processType.outboundTag === ProcessTypeOutboundTag.VOEC;
  }

  export function isNewProcess(process: ProcessDto): boolean {
    return !!process.processId ? process.processId < 1 : true;
  }

  export const useFiscalRepresentation = (processId: number): IUseObjectResponse<FiscalRepresentationDto> => {
    const { data, mutate, error, isLoading } = useSWR<FiscalRepresentationDto>(
      `/v1/process/${processId}/fiscal-representation`,
      swrFetcherWith404,
    );

    return {
      data: data ?? null,
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const createFiscalRepresentation = async (
    createRepresentation: Omit<FiscalRepresentationDto, 'processFiscalRepresentationId'>,
  ) => {
    const { processId, ...representation } = createRepresentation;
    const response = await api.post<'/v1/process/{processId}/fiscal-representation'>(
      `${backendUri}/v1/process/${processId}/fiscal-representation`,
      representation,
    );
    return response.data;
  };

  export const updateFiscalRepresentation = async (
    createRepresentation: Omit<FiscalRepresentationDto, 'processFiscalRepresentationId'>,
  ) => {
    const { processId, ...representation } = createRepresentation;
    const response = await api.put<'/v1/process/{processId}/fiscal-representation'>(
      `${backendUri}/v1/process/${processId}/fiscal-representation`,
      representation,
    );
    return response.data;
  };

  export const getProcessByCustomerId = async (customerId: number): Promise<ProcessDto[]> => {
    const response = await api.get<'/v1/process'>(`${backendUri}/v1/process`, { params: { customerId } });
    return response.data;
  };

  export async function getProcessShopSystemApi(processId: number): Promise<ProcessShopSystemApiDto> {
    const response = await api.get<'/importer/process/{processId}/config'>(
      `${backendUri}/importer/process/${processId}/config`,
      {
        params: { processId },
      },
    );
    return response.data;
  }

  export const useProcessShopSystemApi = (processId: number | null): IUseObjectResponse<ProcessShopSystemApiDto> => {
    const { data, mutate, error, isLoading } = api.useApi<'/importer/process/{processId}/config'>(
      processId !== null && processId > 0 ? { key: '/importer/process/{processId}/config', processId } : null,
    );
    return {
      data: data ?? null,
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const getUnmaskedProcessShopSystemApi = async (processId: number): Promise<ProcessShopSystemApiDto> => {
    const res = await api.get<'/importer/process/{processId}/unmasked-config'>(
      `${backendUri}/importer/process/${processId}/unmasked-config`,
    );
    return res.data;
  };

  export const updateProcessShopSystemApi = async (
    update: ProcessShopSystemApiUpdateDto,
    processId: number,
  ): Promise<void> => {
    const res = await api.put<'/importer/process/{processId}/config'>(
      `${backendUri}/importer/process/${processId}/config`,
      update,
    );
    return res.data;
  };

  export const getProcessById = async (processId: number): Promise<ProcessWithCustomerDto> => {
    const res = await api.get<'/v1/process/{processId}'>(`${backendUri}/v1/process/${processId}`);
    return res.data;
  };

  export const useProcess = (processId?: number): IUseObjectResponse<ProcessWithCustomerDto> => {
    const { data, mutate, isLoading, error } = api.useApi<'/v1/process/{processId}'>(
      processId ? { key: '/v1/process/{processId}', processId } : null,
    );

    return {
      data: data ?? null,
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const useProcesses = (customerId?: number | null): IUseArrayResponse<ProcessWithCustomerDto> => {
    const { data, mutate, error } = api.useApi<'/v1/process'>(customerId === null ? null : '/v1/process', {
      customerId: customerId ?? undefined,
    });

    return {
      data: data || [],
      mutate,
      error,
      isLoading: !error && typeof data === 'undefined',
      isError: !!error,
    };
  };

  export const putProcess = async (body: UpdateProcessDto, id: number): Promise<ProcessDto> => {
    const res = await api.put<'/v1/process/{processId}'>(`${backendUri}/v1/process/${id}`, body);
    return res.data;
  };

  export const deleteProcess = async (processId: number | null, company: string, password: string) => {
    const response = await api.delete<'/v1/process/{processId}'>(`${backendUri}/v1/process/${processId}`, {
      data: { company, password },
    });

    return response.data;
  };

  export async function sendRetoureDigest(processId: number): Promise<CreatedJobDataDto> {
    const res = await api.post<'/v1/process/{processId}/retoureDigest'>(
      `${backendUri}/v1/process/${processId}/retoureDigest`,
    );
    return res.data;
  }

  export const useProcessStats = (processId: number): IUseObjectResponse<GetProcessStatsDto> => {
    const { data, mutate, isLoading, error } = api.useApi<'/v1/process/{processId}/stats'>({
      key: '/v1/process/{processId}/stats',
      processId,
    });

    return {
      data: data ?? null,
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };
}
