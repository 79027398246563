import React from 'react';
import { ConsignmentType } from '../../../shared/backend';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import SendIcon from '@mui/icons-material/LibraryBooks';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class ImportGvmsConsignmentAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private importGvmsTourIds: number[] | null,
    private openDialog: (tourIds: number[], consignmentType: ConsignmentType) => void,
  ) {
    super(
      'import-gvms',
      t('Import GVMS', { ns: 'customs' }),
      t('Import GVMS consignment', { ns: 'customs' }),
      <SendIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.importGvmsTourIds;
  }

  public isDisabled(): boolean {
    return !this.importGvmsTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.importGvmsTourIds?.length) {
      this.openDialog(this.importGvmsTourIds, ConsignmentType.IMPORT_GVMS);
    }
    return Promise.resolve();
  }
}
