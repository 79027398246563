import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import SendIcon from '@mui/icons-material/Send';
import { ConsignmentType } from '../../../shared/backend';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class ExportGvmsConsignmentsAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private exportGvmsTourIds: number[] | null,
    private openDialog: (tourIds: number[], consignmentType: ConsignmentType) => void,
  ) {
    super(
      'export-gvms-consignment',
      t('Export GVMS', { ns: 'customs' }),
      t('Export GVMS consignment', { ns: 'customs' }),
      <SendIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.exportGvmsTourIds;
  }

  public isDisabled(): boolean {
    return !this.exportGvmsTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.exportGvmsTourIds) {
      this.openDialog(this.exportGvmsTourIds, ConsignmentType.EXPORT_GVMS);
    }
    return Promise.resolve();
  }
}
