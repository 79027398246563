import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import DiamondIcon from '@mui/icons-material/LibraryBooks';
import { IsoCountryCode } from '../../../shared/backend';
import { CustomsService } from '../../../services/customs-service/customs.service';
import { JobError, JobService } from '../../../services/job-service/job.service';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';
import { DownloadUtil } from '../../../shared/helper/download-util';

export class EmkDocumentsAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    public batch: {
      destinationCountry: IsoCountryCode;
    },
    private tourIdsWithEmk: number[] | null,
  ) {
    super(
      'emk-documents',
      t('EMK documents', { ns: 'customs' }),
      t('EMK documents', { ns: 'customs' }),
      <DiamondIcon />,
      mutate,
      notifications,
    );
  }

  private get isNorwayProcessType(): boolean {
    return this.batch.destinationCountry === IsoCountryCode.NO;
  }

  public isAvailable(): boolean {
    return !!this.tourIdsWithEmk && !this.isNorwayProcessType;
  }

  public isDisabled(): boolean {
    return !this.tourIdsWithEmk?.length;
  }

  public async execute(): Promise<void> {
    if (this.tourIdsWithEmk?.length) {
      await this.handleEmkDocumentDownload(this.tourIdsWithEmk);
    }
    return Promise.resolve();
  }

  private async handleEmkDocumentDownload(tourIds: number[]): Promise<void> {
    const jobData = await CustomsService.createEmkDocuments({ tourIds });

    try {
      const jobObject = await JobService.getInstance().addJobPromise(jobData.jobId.toString());
      const binaryBuffer = jobObject.returnvalue.file;

      if (binaryBuffer) {
        this.notifications.addInfo(
          t('Emk documents for tours {{tourIds}} have been successfully created and will be downloaded.', {
            tourIds: tourIds.join(', '),
            ns: 'customs',
          }),
        );
        const zipBuffer = Buffer.from(binaryBuffer, 'binary');
        DownloadUtil.downloadFile(zipBuffer, JobService.getJobResultFileName(jobObject), `application/zip`);
      } else {
        this.notifications.addError(
          t(`Dokument aus Job {{jobId}} konnte nicht heruntergeladen werden`, { jobId: jobData?.jobId, ns: 'customs' }),
        );
      }
    } catch (error) {
      if (error instanceof JobError) {
        this.notifications.addError(
          t('Error when creating the Emk documents for the tours {{tourIds}}: {{message}}', {
            tourIds: tourIds.join(', '),
            message: error.getJobState().failedReason,
            ns: 'customs',
          }),
        );
      } else {
        this.notifications.addError(error);
      }
    }
  }
}
