import React from 'react';
import { ConsignmentType } from '../../../shared/backend';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class SumAConsignmentsAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private sumATourIds: number[] | null,
    private openDialog: (tourIds: number[], consignmentType: ConsignmentType) => void,
  ) {
    super(
      'sum-a-consignment',
      t('SumA', { ns: 'customs' }),
      t('SumA consignment', { ns: 'customs' }),
      <LibraryBooksIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.sumATourIds;
  }

  public isDisabled(): boolean {
    return !this.sumATourIds?.length;
  }

  public execute(): Promise<void> {
    if (this.sumATourIds?.length) {
      this.openDialog(this.sumATourIds, ConsignmentType.SUMA);
    }
    return Promise.resolve();
  }
}
