import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { useState } from 'react';

const ButtonAsync: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Button
      {...props}
      disabled={props.disabled || isLoading}
      startIcon={
        isLoading ? (
          <CircularProgress
            size="1em"
            color="inherit"
          />
        ) : (
          props.startIcon
        )
      }
      onClick={(ev) => {
        if (props.onClick) {
          setLoading(true);
          Promise.resolve(props.onClick(ev)).finally(() => setLoading(false));
        }
      }}
    >
      {children}
    </Button>
  );
};

export default ButtonAsync;
