import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import KeyboardReturnIcon from '@mui/icons-material/LibraryBooks';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class DeImportDocumentsAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private deImportTourIds: number[] | null,
    private showDeImportModal: () => void,
  ) {
    super(
      'de-import-documents',
      t('DE import documents', { ns: 'customs' }),
      null,
      <KeyboardReturnIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.deImportTourIds;
  }

  public isDisabled(): boolean {
    return !this.deImportTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.deImportTourIds?.length) {
      this.showDeImportModal();
    }
    return Promise.resolve();
  }
}
