import React from 'react';
import { IsoCountryCode, TourType } from '../../../shared/backend';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import GavelIcon from '@mui/icons-material/Gavel';
import { ProcessService } from '../../../services/process-service/process.service';
import { JobError, JobService } from '../../../services/job-service/job.service';
import { CustomsService } from '../../../services/customs-service/customs.service';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';
import { DownloadUtil } from '../../../shared/helper/download-util';
import { ProcessTypeDto } from '../../../services/process-type-service/process-type.service';

export class PostNordCsvAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    public batch: {
      tourBatchId: string | null;
      destinationCountry: IsoCountryCode;
    },
    private type: TourType,
    private process: { processType: Pick<ProcessTypeDto, 'outboundTag'> } | null,
    private isSingleTour: boolean | null,
    private setPostNordZipProgress: (progress: number) => void,
    postNordZipProgress: number,
  ) {
    const progressText = postNordZipProgress > 0 ? ` (${postNordZipProgress}%)` : '';
    super('post-nord-zip', `${t('Post Nord ZIP')}${progressText}`, null, <GavelIcon />, mutate, notifications);
  }

  private get isNorwayProcessType(): boolean {
    return this.batch.destinationCountry === IsoCountryCode.NO;
  }

  private get isReturnFromNorway(): boolean {
    return this.type === TourType.RETOUR && this.isNorwayProcessType;
  }

  public isAvailable(): boolean {
    return (
      !!this.process &&
      !ProcessService.isProcessUsingVOEC(this.process) &&
      this.isNorwayProcessType &&
      !this.isReturnFromNorway &&
      !!this.batch.tourBatchId &&
      !this.isSingleTour
    );
  }

  public isDisabled(): boolean {
    return !this.batch.tourBatchId;
  }

  public async execute(): Promise<void> {
    if (this.batch.tourBatchId) {
      await this.handleCreatePostNordCsv(this.batch.tourBatchId);
    }
    return Promise.resolve();
  }

  private async handleCreatePostNordCsv(tourBatchId: string): Promise<void> {
    const jobData = await CustomsService.createPostNordCsv({ tourBatchId });

    try {
      const jobObject = await JobService.getInstance().addJobPromise(jobData.jobId.toString(), (_, { progress }) =>
        this.setPostNordZipProgress(progress * 100),
      );
      const binaryBuffer = jobObject.returnvalue.file;

      if (binaryBuffer) {
        this.notifications.addSuccess(
          t('Post Nord CSV for tour batch {{tourBatchId}} was successfully created and is being downloaded.', {
            tourBatchId,
            ns: 'customs',
          }),
        );
        const zipBuffer = Buffer.from(binaryBuffer, 'binary');
        DownloadUtil.downloadFile(zipBuffer, JobService.getJobResultFileName(jobObject), `application/zip`);
      } else {
        this.notifications.addError(
          t('Document from job {{jobId}} could not be downloaded', { jobId: jobData.jobId, ns: 'customs' }),
        );
      }

      this.mutate();
    } catch (error) {
      if (error instanceof JobError) {
        this.notifications.addError(
          t('Error creating the Post Nord CSV for Tour Batch {{tourBatchId}}: {{message}}', {
            tourBatchId,
            message: error.getJobState().failedReason,
            ns: 'customs',
          }),
        );
      } else {
        this.notifications.addError(error);
      }
    } finally {
      this.setPostNordZipProgress(0);
    }
  }
}
