import React from 'react';
import { ConsignmentType } from '../../../shared/backend';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import LibraryBooksIcon from '@mui/icons-material/Send';
import { INotifications } from '../../../hooks/useNotifications';
import { t } from 'i18next';

export class TransitConsignmentAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    private transitTourIds: number[] | null,
    private openDialog: (tourIds: number[], consignmentType: ConsignmentType) => void,
  ) {
    super(
      'transit-consignment',
      t('Transit', { ns: 'customs' }),
      t('Transit consignment', { ns: 'customs' }),
      <LibraryBooksIcon />,
      mutate,
      notifications,
    );
  }

  public isAvailable(): boolean {
    return !!this.transitTourIds;
  }

  public isDisabled(): boolean {
    return !this.transitTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.transitTourIds?.length) {
      this.openDialog(this.transitTourIds, ConsignmentType.TRANSIT);
    }
    return Promise.resolve();
  }
}
