import React from 'react';
import { AbstractCustomsAction } from './AbstractCustomsAction';
import KeyboardReturnIcon from '@mui/icons-material/Send';
import { IsoCountryCode, TourType } from '../../../shared/backend';
import { t } from 'i18next';
import { INotifications } from '../../../hooks/useNotifications';
import { TourService } from '../../../services/tour-service/tour.service';
import { DownloadUtil } from '../../../shared/helper/download-util';

export class DeBatchImportPositionsCsvAction extends AbstractCustomsAction {
  constructor(
    mutate: () => void,
    notifications: INotifications,
    public batch: {
      tourBatchId: string | null;
      destinationCountry: IsoCountryCode;
    },
    private type: TourType,
    private isSingleTour: boolean | null,
    private deImportTourIds: number[] | null,
  ) {
    super(
      'de-batch-import-positions',
      t('Return position details', { ns: 'customs' }),
      null,
      <KeyboardReturnIcon />,
      mutate,
      notifications,
    );
  }

  private get isReturnFromGb(): boolean {
    return this.type === TourType.RETOUR && this.batch.destinationCountry === IsoCountryCode.GB;
  }

  private get isNorwayProcessType(): boolean {
    return this.batch.destinationCountry === IsoCountryCode.NO;
  }

  private get isReturnFromNorway(): boolean {
    return this.type === TourType.RETOUR && this.isNorwayProcessType;
  }

  public isAvailable(): boolean {
    return (
      !this.isSingleTour &&
      !!this.deImportTourIds &&
      !!this.batch.tourBatchId &&
      (this.isReturnFromGb || this.isReturnFromNorway)
    );
  }

  public isDisabled(): boolean {
    return !this.deImportTourIds?.length;
  }

  public async execute(): Promise<void> {
    if (this.deImportTourIds?.length && this.batch.tourBatchId) {
      await this.handleCreateDeBatchImportPositionsCsv(this.batch.tourBatchId);
    }
    return Promise.resolve();
  }

  private async handleCreateDeBatchImportPositionsCsv(tourBatchId: string): Promise<void> {
    try {
      const response = await TourService.getDeBatchImportPositionsCsv(tourBatchId);

      const filename = `${tourBatchId}_positions.csv`;

      DownloadUtil.downloadFile(new Blob([response]), filename, 'text/csv');
    } catch (error) {
      this.notifications.addError(error);
    }
  }
}
